@font-face {
  font-family: 'Baloo2';
  src: url('./assets/font/Baloo_2/Baloo2-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'YatraOne';
  src: url('./assets/font/Yatra_One/YatraOne-Regular.ttf') format('truetype');
}

.ff_yatra {
  font-family: 'YatraOne', sans-serif !important;
}

.ff_baloo {
  font-family: 'Baloo2', sans-serif !important;
}

/* Preloader Start */
body{
  overflow-x: hidden;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: bg 2s ease-in-out infinite alternate;
}

.loading {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.loading div {
  position: absolute;
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 147, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loading div:nth-child(2) {
  animation-delay: -0.7s;
}

@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

/* Preloader End */
 
.backgroundImage {
  background-image: url('./images/bg-image.avif.jpeg');
  /* background-color: #f0f0f0; */
  height: 100% !important;
  width: 100%; /* Ensure it spans the full viewport width */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover; /* Ensure full width with possible cropping */
  position: absolute;
  top: 0% !important;
  z-index: -1; /* Ensure it stays behind other elements */
  left: 0; /* Align it to the left edge */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3 ); 
  z-index: -1;
}
.focus-input:focus {
  border-color: green;
  outline: none;
  box-shadow: 0 0 3px rgba(2, 159, 2, 0.5);
}


.map {
  /* background-image: url('./images/map-color-2.svg'); */
  height: 100vh !important;
  width: 100vw; /* Ensure it spans the full viewport width */
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover;   */
  position: absolute;
  top: 0% !important;
  z-index: -1; /* Ensure it stays behind other elements */
  left: 0; /* Align it to the left edge */
}

.map-image img{
  height: 520px;
}
.map-image{ 
  display: flex;
  justify-content: center;
}
.logo  {
  height: 80px !important; 
}

/* Login Form Start */

@media only screen and (min-device-width : 1360px) and (max-device-width : 1440px) {
  .imgTwo {
    top: 17% !important;
    left: 57% !important;
  }

  .imgOne {
    top: 59% !important;
  }

}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  .imgTwo {
    top: 17% !important;
    left: 60% !important;
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 425px) {
  .imgTwo {
    top: 10% !important;
    left: 60% !important;
  }
}
  
  .yatra {
    font-family: 'YatraOne', sans-serif !important;
  }
 .map{
  background-image: url('./images/map-color-2.svg');
  height: 100vh;
  width: 100%;
 }